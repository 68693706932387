import { axiosInstance } from "./axiosInstance";

// get
export const getAllData = async (endpoint) => {
  const result = await axiosInstance
    .get(endpoint)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
  return result;
};

// post
export const storeData = async (endpoint, data) => {
  const result = await axiosInstance

    .post(endpoint, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });

  return result;
};

// delete
export const deleteById = async (endpoint) => {
  const result = await axiosInstance
    .delete(endpoint)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
  return result;
};

// update
export const updateData = async (endpoint, updatedData) => {
  const result = await axiosInstance
    .patch(endpoint, updatedData)
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
  return result;
};

// // filter
// export const getFilteredData = async (endpoint, filteredOptions) => {
//   const result = await axiosInstance
//     .post(endpoint, filteredOptions)
//     .then((res) => {
//       return res;
//     })
//     .catch((err) => {
//       return err;
//     });
//   return result;
// };

// // register
// export const createAccount = async (endpoint, email, password) => {
//   const respond = await axiosInstance
//     .post(endpoint, { email: email, password: password })
//     .then((res) => {
//       console.log(res);
//       return res;
//     })
//     .catch((error) => {
//       console.log("Error Registering User", error);
//       return error;
//     });
//   return respond;
// };

// // login
// export const userLogin = async (endpoint, email, password) => {
//   const bodyData = { email: email, password: password };
//   const respond = await axiosInstance
//     .post(endpoint, bodyData)
//     .then((res) => {
//       return res.data;
//     })
//     .catch((error) => {
//       console.log("Error Loggin", error);
//       return error;
//     });

//   return respond;
// };

// // find consultancy
// export const findConsultancy = async (endpoint, id) => {
//   const bodyData = { id: id };
//   const response = await axiosInstance
//     .post(endpoint, bodyData)
//     .then((res) => {
//       return res.data;
//     })
//     .catch((error) => {
//       console.log("Error Getting Data", error);
//       return error;
//     });
//   return response;
// };

// export const findConsultancyProfileById = async (endpoint, id) => {
//   const bodyData = { id: id };
//   const response = await axiosInstance
//     .get(endpoint, bodyData)
//     .then((res) => {
//       return res.data;
//     })
//     .catch((error) => {
//       console.log("Error getting data", error);
//       return error;
//     });
//   return response;
// };

// export const updateConsultancyData = async (endpoint, payload) => {
//   const response = await axiosInstance
//     .get(endpoint, payload)
//     .then((res) => {
//       return res.data;
//     })
//     .catch((error) => {
//       console.log("Error getting data", error);
//       return error;
//     });
//   return response;
// };
