import React, { useState } from "react";
import "./SubmitForm.scss";
import { PiBookmarkFill } from "react-icons/pi";
import { storeData } from "../../api/apiService";
import Popup from "../popup/Popup";

const SubmitForm = ({ onClose }) => {
  // data
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState({});

  // // for list consultancy button
  // const [isPopOpen, setIsPopOpen] = useState(true);
  // const closePopup = () => {
  //   setIsPopOpen(false);
  // };

  // submission message
  const [submissionMessage, setSubmissionMessage] = useState(""); // for success or failure message
  const [messageType, setMessageType] = useState(""); // to handle CSS for success or error

  // Form validation
  const validate = () => {
    let tempErrors = {};

    // Validate consultancy name
    if (!name) {
      tempErrors.name = "Consultancy name is required";
    }

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      tempErrors.email = "Email is required";
    } else if (!emailRegex.test(email)) {
      tempErrors.email = "Please enter a valid email";
    }

    // Validate phone number
    const phoneRegex = /^\+977\d{10}$/;
    if (!phoneNo) {
      tempErrors.phoneNo = "Phone number is required";
    } else if (!phoneRegex.test(phoneNo)) {
      tempErrors.phoneNo = "Please enter a valid number";
    }

    // Validate website (optional)
    if (website) {
      const websiteRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,4}\/?$/;
      if (!websiteRegex.test(website)) {
        tempErrors.website = "Please enter a valid website URL";
      }
    }

    setError(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // handle submit list consultancy buttons
  const handleSubmitListConsultancy = () => {
    if (validate()) {
      const newConsultancy = {
        consultancyName: name,
        phoneNumber: phoneNo,
        email: email,
        website: website,
      };
      storeData("consultancy/addconsultancydata", newConsultancy)
        .then((response) => {
          setSubmissionMessage("Your request has been submitted.");
          setMessageType("success", response); // Set message type to success
          closePopup(); // Close popup after successful submission

          // Clear the form
          setName("");
          setEmail("");
          setPhoneNo("");
          setWebsite("");

          setTimeout(() => {
            setSubmissionMessage("");
            setMessageType("");
          }, 2000);
        })
        .catch((error) => {
          setSubmissionMessage(
            "There was an error submitting your request. Please try again."
          );
          setMessageType("error"); // Set message type to error
        });
    } else {
      console.log("Validation errors:", error);
    }
  };

  return (
    <Popup isOpen={true} onClose={onClose}>
      <div className="student-request">
        <div className="title-section">
          <div className="title-1">
            <div className="save-icon">
              <PiBookmarkFill />
            </div>
            <div className="title-text">Submit Request</div>
          </div>
          <div className="description">
            Fill the form and submit to get response from the consultancies.
          </div>
        </div>
        <form>
          <div className="input-box">
            <label>
              Full Name <span>*</span>
              <span className="error-msg">{error.name ? error.name : ""}</span>
            </label>
            <input
              type="text"
              placeholder="Your Full Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>

          <div className="input-box">
            <label>
              Email <span>*</span>
              <span className="error-msg">
                {error.email ? error.email : ""}
              </span>
            </label>
            <input
              type="email"
              placeholder="Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="input-box">
            <label>
              Phone Number <span>*</span>
              <span className="error-msg">
                {error.phoneNo ? error.phoneNo : ""}
              </span>
            </label>
            <input
              type="text"
              placeholder="+977 98XXXXXXXX"
              value={phoneNo}
              onChange={(e) => setPhoneNo(e.target.value)}
              required
            />
          </div>

          <div className="input-box">
            <label>
              Current Location <span>(optional)</span>
              <span className="error-msg">
                {error.address ? error.address : ""}
              </span>{" "}
            </label>
            {/* <p>To recommend you the nearest consultancies.</p> */}
            <input
              type="text"
              placeholder="Enter yout address line (Street name, City)"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>

          <div className="input-box">
            <label>
              Your Message <span>(optional)</span>
              <span className="error-msg">
                {error.message ? error.message : ""}
              </span>
            </label>
            {/* <p>
              Your message to us that you want to send about you or your
              interest.
            </p> */}
            <textarea
              type="text"
              placeholder="Message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>

          <div className="terms-box">
            <input type="checkbox" name="termsCondition" id="termsConsition" />
            <p>
              By Requesting you agreed to our <span>Terms and Condition.</span>
            </p>
          </div>

          <div className="button">
            <div className="cancel-btn" onClick={onClose}>
              Cancel
            </div>
            <div className="submit-btn" onClick={handleSubmitListConsultancy}>
              Submit
            </div>
          </div>
        </form>
      </div>
    </Popup>
  );
};

export default SubmitForm;
