import React from "react";
import "./Hero.scss";

import Number from "../../components/number/Number";
import Filter from "../../components/filter/Filter";

export default function Hero() {
  return (
    <section className="hero">
      <div class="ribbon">
        Launching Soon
        <div className="move-letter">!</div>
      </div>

      <div className="row-1">
        <div className="title">
          <p>RIGHT GUIDANCE,</p>
          <p>BRIGHT FUTURE.</p>
        </div>
        <Number />
      </div>
      <div className="row-2">
        <Filter />
      </div>
    </section>
  );
}
