import React from "react";
import "./ConsultancyCard.scss";
import success from "../../assets/favicons/success.svg";
import verified from "../../assets/favicons/verified.svg";
import sortlistFill from "../../assets/favicons/sortlist-fill.png";
import sortlist from "../../assets/favicons/sortlist.png";
import visa from "../../assets/favicons/visa-applied.svg";
import pan from "../../assets/favicons/pan.svg";

export default function ConsultancyCard({
  consultancy,
  onSelect,
  onSortlist,
  isSortlisted,
}) {
  return (
    <div className="consultancy-card" onClick={onSelect}>
      <div className="row-1">
        <div className="image">
          <img src={consultancy.image} alt={consultancy.name} />
        </div>
        <div className="title">
          <div className="text">{consultancy.name}</div>
          <div className="other">
            {/* <div className="success">
              <div className="icon">
                <img src={success} alt="success" />
              </div>
              <div className="text">{consultancy.successRate}&#37;Success</div>
            </div> */}
            <div className="ecan">
              <div className="icon">
                <img src={verified} alt="verified" />
              </div>
              <div className="text">ECAN</div>
            </div>
          </div>
        </div>
        <div className="sortlist-btn" onClick={onSortlist}>
          {isSortlisted ? (
            <img src={sortlistFill} alt="sortlist fill" />
          ) : (
            <img src={sortlist} alt="sortlist" />
          )}
        </div>
      </div>
      <div className="row-2">
        <div className="other">
          {/* <div className="visa">
            <div className="icon">
              <img src={visa} alt="visa" />
            </div>
            <div className="number">{consultancy.noOfVisas} </div>
            <div className="text">Visa</div>
          </div> */}

          <div className="pan">
            <div className="icon">
              <img src={pan} alt="pan" />
            </div>
            <div className="number">{consultancy.panNumber}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
