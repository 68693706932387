import React, { useState } from "react";
import "./Browse.scss";
import ConsultancyCard from "../../components/conCard/ConsultancyCard";
import ConsultancyDetail from "../../components/conDetail/ConsultancyDetail";
import Sortlist from "../../components/sortPop/Sortlist";
import SubmitForm from "../../components/formPop/SubmitForm";

export default function Browse() {
  // List of consultancies from the database (without the sortlisted status)
  const [consultancies, setConsultancies] = useState([
    {
      id: 1,
      name: "The Next Education Consultancy Pvt. Ltd.",
      image: "https://via.placeholder.com/100",
      successRate: 85,
      panNumber: "A1B2C3D4E5",
      noOfVisas: 150,
      verified: true,
      description: "We provide quality consultancy for overseas education.",
      destination: ["USA", "Canada"],
      university: ["Harvard", "MIT"],
      course: ["Engineering", "Business"],
    },
    {
      id: 2,
      name: "AECC-Education Consultants",
      image: "https://via.placeholder.com/100",
      successRate: 90,
      panNumber: "X1Y2Z3D4E5",
      noOfVisas: 200,
      verified: true,
      description: "Overseas education specialists for Europe and Asia.",
      destination: ["UK", "Germany"],
      university: ["Oxford", "Cambridge"],
      course: ["Arts", "Medical"],
    },
    {
      id: 3,
      name: "Kangaroo Education Foundation",
      image: "https://via.placeholder.com/100",
      successRate: 90,
      panNumber: "X1Y2Z3D4E5",
      noOfVisas: 200,
      verified: true,
      description: "Overseas education specialists for Europe and Asia.",
      destination: ["UK", "Germany"],
      university: ["Oxford", "Cambridge"],
      course: ["Arts", "Medical"],
    },
  ]);

  // State to track the selected consultancy
  const [selectedConsultancy, setSelectedConsultancy] = useState(null);

  // State to track the IDs of shortlisted consultancies
  const [sortlistedConsultancyIds, setSortlistedConsultancyIds] = useState([]);

  // State to show/hide the sortlist popup and the form popup
  const [showSortlistPopup, setShowSortlistPopup] = useState(false);
  const [showSubmitForm, setShowSubmitForm] = useState(false);

  // Function to toggle the shortlist state of a consultancy
  const toggleSortlist = (event, consultancy) => {
    event.stopPropagation();
    const isAlreadySortlisted = sortlistedConsultancyIds.includes(
      consultancy.id
    );

    // Add or remove consultancy ID from the shortlisted state
    const updatedSortlist = isAlreadySortlisted
      ? sortlistedConsultancyIds.filter((id) => id !== consultancy.id)
      : [...sortlistedConsultancyIds, consultancy.id];

    setSortlistedConsultancyIds(updatedSortlist);
    setShowSortlistPopup(true);
  };

  const openFormPopup = () => {
    setShowSubmitForm(true);
  };

  // Function to check if a consultancy is shortlisted
  const isSortlisted = (consultancyId) => {
    return sortlistedConsultancyIds.includes(consultancyId);
  };

  return (
    <div className="browse-container">
      {/* Left column with consultancy cards */}
      <div className="left-column">
        {consultancies.map((consultancy) => (
          <ConsultancyCard
            key={consultancy.id}
            consultancy={consultancy}
            onSelect={() => setSelectedConsultancy(consultancy)}
            onSortlist={(event) => toggleSortlist(event, consultancy)}
            isSortlisted={isSortlisted(consultancy.id)}
          />
        ))}
      </div>

      {/* Right column with consultancy details */}
      <div className={`${selectedConsultancy ? "active" : ""} right-column`}>
        {selectedConsultancy ? (
          <ConsultancyDetail
            consultancy={selectedConsultancy}
            onSortlist={(event) => toggleSortlist(event, selectedConsultancy)}
            isSortlisted={isSortlisted(selectedConsultancy.id)}
            closeTab={() => setSelectedConsultancy(null)}
          />
        ) : (
          <ConsultancyDetail
            consultancy={consultancies[0]}
            onSortlist={(event) => toggleSortlist(event, consultancies[0])}
            isSortlisted={isSortlisted(consultancies[0].id)}
          />
        )}
      </div>

      {/* Sortlist popup */}
      <Sortlist
        consultancies={consultancies.filter((c) =>
          sortlistedConsultancyIds.includes(c.id)
        )} // Only show shortlisted consultancies
        show={showSortlistPopup}
        toggleSortlist={toggleSortlist}
        onClear={() => setSortlistedConsultancyIds([])}
        onSubmit={openFormPopup}
      />

      {/* Submit form popup */}
      {showSubmitForm && (
        <SubmitForm onClose={() => setShowSubmitForm(false)} />
      )}
    </div>
  );
}
