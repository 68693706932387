import React from "react";
import "./ConsultancyDetail.scss";
import success from "../../assets/favicons/success.svg";
import verified from "../../assets/favicons/verified.svg";
import sortlistFill from "../../assets/favicons/sortlist-fill.png";
import sortlist from "../../assets/favicons/sortlist.png";
import visa from "../../assets/favicons/visa-applied.svg";
import pan from "../../assets/favicons/pan.svg";

export default function ConsultancyDetail({
  consultancy,
  onSortlist,
  isSortlisted,
  closeTab,
}) {
  return (
    <div className="consultancy-detail">
      {/* <h2>{consultancy.name}</h2>
      <img src={consultancy.image} alt={consultancy.name} />
      <p>{consultancy.description}</p>
      <p>Success Rate: {consultancy.successRate}%</p>
      <p>Visas: {consultancy.noOfVisas}</p>
      <p>PAN Number: {consultancy.panNumber}</p>
      <button onClick={onSortlist}>
        {isSortlisted ? "Remove from Sortlist" : "Sortlist"}
      </button> */}

      <div className="header">
        <div className="title">{consultancy.name}</div>
        <div className="sortlist-btn">
          <button onClick={onSortlist}>
            {isSortlisted ? (
              <img src={sortlistFill} alt="sortlist fill" />
            ) : (
              <img src={sortlist} alt="sortlist" />
            )}{" "}
          </button>
        </div>
      </div>

      <div className="statistic">
        <div className="box">
          <div className="icon">
            <img src={success} alt="success" />
          </div>
          <div className="content">
            <div className="text-1">{consultancy.successRate}&#37;</div>
            <div className="text-2">Success</div>
          </div>
        </div>
        <div className="box">
          <div className="icon">
            <img src={verified} alt="verified" />
          </div>
          <div className="content">
            <div className="text-1">
              {consultancy.verified ? "Verified" : "Pending"}
            </div>
            <div className="text-2">ECAN</div>
          </div>
        </div>
        <div className="box">
          <div className="icon">
            <img src={visa} alt="visa" />
          </div>
          <div className="content">
            <div className="text-1">{consultancy.noOfVisas}</div>
            <div className="text-2">Visa Applied</div>
          </div>
        </div>
        <div className="box">
          <div className="icon">
            <img src={pan} alt="pan" />
          </div>
          <div className="content">
            <div className="text-1">{consultancy.panNumber}</div>
            <div className="text-2">PAN</div>
          </div>
        </div>
      </div>

      <div className="description">{consultancy.description}</div>

      <div className="destination">
        <div className="title">Destination</div>
        <div className="option">
          {consultancy.destination.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      </div>
      <div className="university">
        <div className="title">University</div>
        <div className="option">
          {consultancy.university.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      </div>
      <div className="course">
        <div className="title">Course</div>
        <div className="option">
          {consultancy.course.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      </div>
      <button onClick={closeTab}>Close</button>
    </div>
  );
}
