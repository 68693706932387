import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import NotFound from "../src/pages/NotFound/NotFound";
import HomeLayout from "./layouts/HomePage/HomeLayout";

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          {/* <Route
            path="/login"
            render={() =>
              isAuthenticated() ? <Redirect to="/" /> : <LoginPage />
            }
          /> */}
          {/* <Route path="/test" exact component={Dump} /> */}
          <Route path="/" exact component={HomeLayout} />

          {/* Catch- all route for unmatched routes */}
          <Route path="/not-found" component={NotFound} />
          <Route path="*" render={() => <Redirect to="/not-found" />} />
        </Switch>
      </Router>
    );
  }
}

export default App;

// const isAuthenticated = () => {
//   // You can replace this with your own logic
//   return localStorage.getItem("access-token") !== null;
// };
