import React from "react";
import Navbar from "../../section/Navbar/Navbar";
import Hero from "../../section/Hero/Hero";
import Browse from "../../section/Browse/Browse";
import HowItWorks from "../../section/HowItWorks/HowItWorks";
import Footer from "../../section/Footer/Footer";
import "./HomeLayout.scss";

export default function HomePageLayout() {
  return (
    <div className="home-layout">
      <Navbar />
      <div className="main">
        <Hero />
        <Browse />
        <HowItWorks />
        <Footer />
      </div>
    </div>
  );
}
