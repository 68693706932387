import React from "react";
import "./Number.scss";
import {
  FaCity,
  FaGlobeAmericas,
  FaUniversity,
  FaUserGraduate,
} from "react-icons/fa";

export default function Number() {
  return (
    <div className="number-section">
      <div className="text">
        Guiding lakhs of Students and Parents to find right university.
      </div>
      <div className="number">
        <div className="option">
          <div className="icon">
            <FaGlobeAmericas />
          </div>
          <div className="details">
            <div className="count">
              168<span>+</span>
            </div>
            <div className="icon-title">Country</div>
          </div>
        </div>
        <div className="option">
          <div className="icon">
            <FaCity />
          </div>
          <div className="details">
            <div className="count">
              12<span>+</span>
            </div>
            <div className="icon-title">City</div>
          </div>
        </div>
        <div className="option">
          <div className="icon">
            <FaUniversity />
          </div>
          <div className="details">
            <div className="count">
              8<span>+</span>
            </div>
            <div className="icon-title">University</div>
          </div>
        </div>
        <div className="option">
          <div className="icon">
            <FaUserGraduate />
          </div>
          <div className="details">
            <div className="count">
              16<span>+</span>
            </div>
            <div className="icon-title">Course</div>
          </div>
        </div>
      </div>
    </div>
  );
}
