import axios from "axios";
// export const apiUrl = process.env.REACT_APP_API_URL;
// export const apiUrl = "https://consultancy-api.onrender.com/api/";

// const myToken = localStorage.getItem("access-token");
export const axiosInstance = axios.create({
  // baseURL: process.env.REACT_APP_API_URL,
  baseURL: "https://consultancy-api.onrender.com/api/",
  // headers: {
  //   Authorization: `Bearer ${myToken}`,
  // },
});
