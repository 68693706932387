import React from "react";
import "./Filter.scss";
import target from "../../assets/favicons/target.png";
import { FaArrowRight, FaGlobeAmericas } from "react-icons/fa";
import { TiArrowSortedDown } from "react-icons/ti";
import { FiMapPin } from "react-icons/fi";

import city from "../../assets/favicons/city.svg";
import uni from "../../assets/favicons/uni.svg";
import course from "../../assets/favicons/course.svg";

// popular options
import nextEdu from "../../assets/images/next-edu.png";
import aecc from "../../assets/images/aecc.png";
import kangaroo from "../../assets/images/kangaroo.png";

export default function Filter() {
  return (
    <div className="filter">
      <div className="title">
        <div className="text-1">
          <img src={target} alt="target-icon" />
          FIND CONSULTANCY BY
        </div>
        <div className="text-2">
          <FiMapPin />
          Select Consultancy Location
        </div>
      </div>
      <div className="dropdown-box">
        <div className="dropdown-options">
          <div className="dropdown">
            <div className="title">
              <div className="icon">
                <FaGlobeAmericas />
              </div>
              Countries
            </div>
            <div className="arrow-icon">
              <TiArrowSortedDown />
            </div>
          </div>{" "}
          <div className="dropdown">
            <div className="title">
              <div className="icon">
                {/* <FaCity /> */}
                <img src={city} alt="city" />
              </div>
              Cities
            </div>
            <div className="arrow-icon">
              <TiArrowSortedDown />
            </div>
          </div>
          <div className="dropdown">
            <div className="title">
              <div className="icon">
                {/* <FaUniversity /> */}
                <img src={uni} alt="uni" />
              </div>
              Universities
            </div>
            <div className="arrow-icon">
              <TiArrowSortedDown />
            </div>
          </div>
          <div className="dropdown">
            <div className="title">
              <div className="icon">
                {/* <FaGraduationCap /> */}
                <img src={course} alt="course" />
              </div>
              Courses
            </div>
            <div className="arrow-icon">
              <TiArrowSortedDown />
            </div>
          </div>
          <div className="filter-btn">
            <div className="btn-text">Search</div>
            <div className="btn-icon">
              <FaArrowRight />
            </div>
          </div>
        </div>

        <div className="popular-options">
          <div className="title">Popular Consultancies</div>
          <div className="options">
            <div className="option">
              <img src={nextEdu} alt="next education" />
              <p>The Next Education Consultancy Pvt. Ltd.</p>
            </div>
            <div className="option">
              <img src={aecc} alt="aecc" />
              <p>AECC-Education Consultants</p>
            </div>
            <div className="option">
              <img src={kangaroo} alt="kangaroo" />
              <p>Kangaroo Education Foundation</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
